<template>
    <el-dialog :visible="visible" width="60%" @close="handleClose" class="dialog" :title="title" @open="getData">
        <div style="height: 50vh; overflow: auto;">
            <div style="text-align: center;padding-bottom:20px">
                <h3>{{ form.orgName }}</h3>
                <div>{{ form.source }}</div>
            </div>
            <div v-if="report.jlb">{{ report.jlb }}</div>
            <div v-if="report.hyb && report.hyb.length > 0">
                <h3 style="text-align: left;">检验项目</h3>
                <el-table :data="report.hyb" stripe style="width: 100%">
                    <el-table-column prop="group_name" label="分类" width="" />
                    <el-table-column prop="item_name" label="项目" width="" />
                    <el-table-column prop="value" label="结果" width="" />
                    <el-table-column prop="unit" label="单位" width="" />
                    <el-table-column prop="default" label="参考值" width="" />
                    <el-table-column prop="flag" label="" width="" />
                    <el-table-column prop="report_time" label="检查日期" width="200" />
                </el-table>
            </div>
            <div v-if="report.jcb && report.jcb.length > 0">
                <h3 style="text-align: left;">检查项目</h3>
                <el-table :data="report.jcb" stripe style="width: 100%">
                    <el-table-column prop="group_name" label="分类" width="" />
                    <el-table-column prop="item_name" label="项目" width="" />
                    <el-table-column prop="value" label="结果" width="120" />
                    <el-table-column prop="unit" label="单位" width="90" />
                    <el-table-column prop="default" label="参考值" width="" />
                    <el-table-column prop="flag" label="" width="" />
                    <el-table-column prop="created" label="检查日期" width="200" :formatter="datetimeFormatter" />
                </el-table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: "详情"
        },
    },
    data() {
        return {
            innerVisible: false,
            roleList: [],
            report: {
            }
        }
    },
    methods: {
        getData() {
            this.dialogVisible = false
            this.$http.get(`/hut/report/${this.form.id}`)
                .then(resp => {
                    this.report = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        datetimeFormatter(row, column) {
            const value = row[column.property]
            if (!value) return ''
            return value
            // return new Date(value).format('yyyy-MM-dd hh:mm:ss')
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
